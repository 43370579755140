<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>

      <!-- Title -->
      <v-card-title>
        <h3 v-if="title">{{ title }}</h3>
        <h3 v-else>{{$t('common.delete.title', {label: label})}}</h3>
      </v-card-title>

      <!-- Text -->
      <v-card-text v-if="text">
        <p>{{ text }}</p>
      </v-card-text>

      <v-card-actions>
        <v-btn
          :loading="isLoading"
          @click="customSubmit"
          color="error"
         class="ml-3">
          {{$t('common.buttons.confirm')}}
        </v-btn>
        <v-spacer />
        <v-btn class="mr-3" @click="close(false)">{{$t('common.buttons.cancel')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import dialogMixin from "@/mixins/dialog"

  export default {
    name: "DeleteDialog",

    mixins: [dialogMixin],

    props: {
      title: {type: String, default: null},
      label: {type: String, default: null},
      text: { type: String, default: null },
      isLoading: {type: Boolean, default: false}
    },

    data() {
      return {
        isLoadingBtnSave: false,
      }
    },

    methods: {
      customSubmit() {

        //this.isLoadingBtnSave = true;
        this.$emit("submit");
      }
    }
  }
</script>

<style scoped>

</style>
